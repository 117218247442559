/* Fonts Styles */
/* Main Styles */
/* Colors Styles */
/* Navigation Styles */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------------------------- Fonts Styles --------------------------------------*/

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/eot/robotobold.eot');
  src: url('./fonts/eot/robotobold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/robotobold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/robotobold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/robotobold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/eot/robotolight.eot');
  src: url('./fonts/eot/robotolight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/robotolight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/robotolight.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/robotolight.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/eot/Robotomedium.eot');
  src: url('./fonts/eot/Robotomedium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/Robotomedium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/Robotomedium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/Robotomedium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/eot/robotoregular.eot');
  src: url('./fonts/eot/robotoregular.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/robotoregular.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/robotoregular.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/robotoregular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/eot/Robotothin.eot');
  src: url('./fonts/eot/Robotothin.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/Robotothin.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/Robotothin.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/Robotothin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/eot/robotoblack.eot');
  src: url('./fonts/eot/robotoblack.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('./fonts/woff2/robotoblack.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('./fonts/woff/robotoblack.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('./fonts/ttf/robotoblack.ttf') format('truetype');
}


@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?5o9bcz');
  src: url('./fonts/icomoon.eot?5o9bcz#iefix') format('embedded-opentype'),
  url('./fonts/icomoon.ttf?5o9bcz') format('truetype'),
  url('./fonts/icomoon.woff?5o9bcz') format('woff'),
  url('./fonts/icomoon.svg?5o9bcz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cloud:before {
  content: "\e93a";
}

.icon-subTopic:before {
  content: "\e93b";
}

.icon-surveys:before {
  content: "\e93c";
}

.icon-menu:before {
  content: "\e900";
}

.icon-dashboard:before {
  content: "\e901";
}

.icon-upload:before {
  content: "\e930";
}

.icon-history:before {
  content: "\e902";
}

.icon-memory:before {
  content: "\e903";
}

.icon-zoom:before {
  content: "\e904";
}

.icon-folder:before {
  content: "\e905";
}

.icon-user:before {
  content: "\e906";
}

.icon-message:before {
  content: "\e907";
}

.icon-upgrade:before {
  content: "\e908";
}

.icon-commission:before {
  content: "\e909";
}

.icon-dataset:before {
  content: "\e90a";
}

.icon-topic:before {
  content: "\e90b";
}

.icon-map:before {
  content: "\e90c";
}

.icon-owner:before {
  content: "\e90d";
}

.icon-partners:before {
  content: "\e90e";
}

.icon-plan:before {
  content: "\e90f";
}

.icon-content:before {
  content: "\e910";
}

.icon-page:before {
  content: "\e911";
}
.icon-autocomplete:before {
  content: "\e910";
}

.icon-label:before {
  content: "\e912";
}

.icon-logo:before {
  content: "\e913";
}

.icon-question:before {
  content: "\e914";
}

.icon-language:before {
  content: "\e915";
}

.icon-settings:before {
  content: "\e916";
}

.icon-email:before {
  content: "\e917";
}

.icon-alphabetic:before {
  content: "\e918";
}

.icon-calendar:before {
  content: "\e919";
}

.icon-cart-view:before {
  content: "\e91a";
}

.icon-line-view:before {
  content: "\e91b";
}

.icon-chrck:before {
  content: "\e91c";
}

.icon-cross:before {
  content: "\e91d";
}

.icon-negative:before {
  content: "\e91e";
}

.icon-add:before {
  content: "\e91f";
}

.icon-attach:before {
  content: "\e920";
}

.icon-time:before {
  content: "\e921";
}

.icon-edit:before {
  content: "\e922";
}

.icon-delete:before {
  content: "\e928";
}

.icon-visit:before {
  content: "\e923";
}

.icon-key:before {
  content: "\e924";
}

.icon-more:before {
  content: "\e925";
}

.icon-options:before {
  content: "\e926";
}

.icon-photo:before {
  content: "\e927";
}

.icon-bin:before {
  content: "\e928";
}

.icon-send:before {
  content: "\e929";
}

.icon-up-direction:before {
  content: "\e92a";
}

.icon-right-direction:before {
  content: "\e92b";
}

.icon-bottom-direction:before {
  content: "\e92c";
}

.icon-left-direction:before {
  content: "\e92d";
}

.icon-top-sign-arrow:before {
  content: "\e92e";
}

.icon-right-sign-arrow:before {
  content: "\e92f";
}

.icon-bottom-sign-arrow:before {
  content: "\e930";
}

.icon-left-sign-arrow:before {
  content: "\e931";
}

.icon-top-small-arrow:before {
  content: "\e932";
}

.icon-right-top-small-arrow:before {
  content: "\e933";
}

.icon-right-small-arrow:before {
  content: "\e934";
}

.icon-right-bottom-small-arrow:before {
  content: "\e935";
}

.icon-bottom-small-arrow1:before {
  content: "\e936";
}

.icon-left-bottom-small-arrow:before {
  content: "\e937";
}

.icon-left-small-arrow:before {
  content: "\e938";
}

.icon-left-top-small-arrow:before {
  content: "\e939";
}

/* -------------------------------------- Main Styles --------------------------------------*/

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  color: #363636;
  background-color: #F5F5F5;
}

* {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  box-sizing: border-box;
}

li {
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.organizer {
  width: 100%;
  float: left;
}

.rightFloat {
  float: right;
}

.leftFloat {
  float: left;
}

.noFloat {
  float: none;
}

.middleContext {
  vertical-align: middle;
}

.inlineView {
  display: inline-block;

}

.tempDisable {
  display: none !important;
}

.spaceBetween {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.fullWidth {
  width: 100%;
}

.textInCenter {
  text-align: center;
}

.boldWeight {
  font-weight: bold;
}

.mediumWeight {
  font-weight: 500;
}

.lightWeight {
  font-weight: 300;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-100 {
  font-size: 100px;
}

.topNormalMargin {
  margin-top: 2rem;
}

.topUnitMargin {
  margin-top: 1rem;
}

.leftNormalMargin {
  margin-left: 1rem;
}

.rightNormalMargin {
  margin-right: 1rem;
}

.normalPadding {
  padding: 1.75rem;
}

/* -------------------------------------- Colors Styles --------------------------------------*/

.blueColor {
  color: #5F65FF;
}

.orangeColor {
  color: #FF8748;
}

.greenColor {
  color: #18C37A;
}

.redColor {
  color: #FF6767;
}

.lightBlueColor {
  color: #12C3F8;
}

.darkBlueColor {
  color: #4CACC9;
}

.pinkColor {
  color: #FD1778;
}

.goldColor {
  color: #E0BD00;
}

.purpleColor {
  color: #6C2AEA;
}

.whiteColor {
  color: #fff;
}

.lightGrayColor {
  color: #C1C1C1;
}

.almostDark {
  color: #5D5D5D;
}

.yellowColor {
  color: #FAA652;
}

.blueBackground {
  background-color: #5F65FF;
}

.grayBackground {
  background-color: #C1C1C1;
}

.orangeBackground {
  background-color: #FF8748;
}

.greenBackground {
  background-color: #18C37A;
}

.redBackground {
  background-color: #FF6767;
}

.lightBlueBackground {
  background-color: #12C3F8;
}

.darkBlueBackground {
  background-color: #4CACC9;
}

.pinkBackground {
  background-color: #FD1778;
}

.goldBackground {
  background-color: #E0BD00;
}

.purpleBackground {
  background-color: #6C2AEA;
}

.grayOutline {
  border: solid #efefef 1px;
}
.outline {
  border: solid #efefef 2px;
  padding: 20px;
}
/* -------------------------------------- Navigation Styles --------------------------------------*/
.mainLogo{
  top:0;
  left:0;
  position: fixed;
  
}
.navDiv{
  width: 20%;
  left: 0;
  top:0;
  height: 100%;
  position: fixed;
}
.navDiv.smallNav{
  width: 5%;
}
.mainNavigationSystem {
  font-size: 18px;
  left: 0;
  height: 94%;
  box-shadow: 10px 0 10px rgba(145, 145, 145, 0.1);
  overflow-y: auto;
  transition: linear 0.2s;
  background-color: #fff;
}
.mainNavigationSystem::-webkit-scrollbar {
  display: none;
}
.mainNavigationSystem .icon {
  margin-right: 1rem;
}

.mainNavigationSystem ul li {
  padding: 0.75rem 1.1rem;
  position: relative;
  right: 1rem;
  width: calc(100% + 1rem);
}

.mainNavigationSystem ul li.alert .icon {
  color: #FF6767;
}

.activeBullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF6767;
  position: relative;
  left: 1rem;
  top: 0.35rem;
  display: inline-block;
  float: right;
}

.mainNavigationSystem ul li.active {
  background: url("./img/nacBackArrow.svg") no-repeat left center;
  background-size: contain;
}

.mainNavigationSystem h2 {
  margin-bottom: 1.25rem;
}

.navigationItem {
  border-bottom: solid #efefef 1px;
  padding: 1rem 2rem;
  clear: both;
  display: inline-block;
  white-space: nowrap;
}




.navigationHeader {
  padding: 1rem 2rem 1rem 1rem;
  top:0;
  left:0;
  display: flex;
    align-items: center;
}
.navigationHeader.smallNav{
  /* width: 35%; */
}



.innerList {
  font-size: 16px;
}

.innerList .icon {
  margin-right: 0.65rem;
  font-size: 14px;
}

ul.innerList li {
  padding: 0.75rem 0;
  right: 0;
}

ul.innerList {
  padding-top: 1rem;
  padding-left: 1.5rem;
}

.pointArrow {
  margin-right: 0;
  position: relative;
  left: 2rem;
  top: 0.35rem;
}

img.logoImage {
  width: 140px;
}
@media (max-width: 500px) {
  .navDiv {
    width: 116px;
  }
  .ImageIcon{
    display: none;
  }
   .listText {
    display: none;
  }
  
  .mainNavigationSystem ul li.active {
    background: none;
  }
  
  .mainNavigationSystem h2 {
    display: none;
  }
  
   ul.innerList {
    padding-left: 0;
  }
  
   .navigationHeader img {
    display: none;
  }
  
   .pointArrow {
    display: none;
  }
  
   .innerList li {
    padding: 0.75rem 0rem;
  }
  
   .innerList li:nth-last-child(1) {
    padding-bottom: 0;
  }
  
   .innerList .icon {
    font-size: 18px;
  }
  
   .activeBullet {
    display: none;
  }
}
.mainNavigationSystem.smallNav {
  /* width:35%; */
}

.smallNav .listText {
  display: none;
}
.logoImage.smallNav{
  display: none;
}

.mainNavigationSystem.smallNav ul li.active {
  background: none;
}

.mainNavigationSystem.smallNav h2 {
  display: none;
}

.smallNav ul.innerList {
  padding-left: 0;
}

.smallNav .navigationHeader img {
  display: none;
}

.smallNav .pointArrow {
  display: none;
}

.smallNav .innerList li {
  padding: 0.75rem 0rem;
}

.smallNav .innerList li:nth-last-child(1) {
  padding-bottom: 0;
}

.smallNav .innerList .icon {
  font-size: 18px;
}

.smallNav .activeBullet {
  display: none;
}

/* -------------------------------------- Admin Boilerplate Styles --------------------------------------*/

.mainContent {
  float: left;
  margin-left: 22%;
  width: 76%;
  padding-top: 3rem;
  padding-left: 2%;
  /* padding: 3rem 3rem 3rem 0 */
}

.mainContent.smallNav {
  margin-left: 7%;
  width: 93%;
}


.adminBoilerplate {
  margin-top: 2rem;
}

.mainHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trendTable {
  width: 49%;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  background-color: #fff;
  padding: 2.5rem;
  position: relative;
}

.trendRow {
  width: 100%;
  float: left;
  margin-bottom: 1rem;
}

.headerRow.trendRow {
  margin-bottom: 1.75rem;
}

.headerRow.trendRow .trendColumn {
  padding-left: 0;
}

.trendRow:nth-last-child(2) {
  margin-bottom: 0;
}

.trendColumn {
  float: left;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.trendColumn:nth-child(1) {
  padding-left: 0;
}

.tableStructure {
  text-align: left;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  border-collapse: collapse;
  border-spacing: 0;
}

.tableHeader {
  font-size: 16px;
  font-weight: 500;
}

.tableStructure td,
.tableStructure th {
  background-image: linear-gradient(rgba(222, 222, 222, 1) 40%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 10px;
  background-repeat: repeat-y;
}

.tdInner {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tableStructure tr {
  font-size: 14px;
  font-weight: 300;
  background-image: linear-gradient(to right, rgba(222, 222, 222, 1) 40%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

/*.tableStructure tbody tr:nth-last-child(0) {
  background: none;
}*/

.tableStructure td,
.tableStructure th {
  padding: 1rem;
}

.tableStructure th {
  font-size: 16px;
}

.tableStructure tr td:nth-last-child(1) {
  text-align: right;
}

.deleteRow {
  font-size: 12px;
  color: #FF2424;
  border-radius: 5px;
  border: solid #efefef 1px;
  padding: 0.6rem 1rem;
  vertical-align: middle;
}

.giveAccess {
  border-radius: 5px;
  border: solid #efefef 1px;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.editRow {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 5px;
  vertical-align: middle;
}

.counterItem {
  width: 32px;
  height: 32px;
  border: solid #D6D6D6 1px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 0.25rem;
}

.counterItem.active {
  background-color: #5F65FF;
  color: #fff;
  border: none;
}

.counterTitle {
  margin-right: 1rem;
}

.pagination .counterItem.active {
  transform: scale(1.5);
  transform-origin: center;
  margin: 0 0.75rem 0 0.5rem;
}

.pagination .counterItem.activeSide {
  transform: scale(1.25);
  border: solid #5F65FF 1px;
  color: #5F65FF;
  margin: 0 0.75rem 0 0.5rem;
}

.viewNavigations {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twoSidePanel {
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  overflow: hidden;
  display: flex;
}

.twoSidePanel table td:nth-last-child(1) {
  background: none;
}

.twoSidePanel table th:nth-last-child(1) {
  background: none;
}

.columnSide .tableStructure {
  box-shadow: none;
  border-radius: 0;
}

.columnSide {
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  min-height: 800px;
  position: relative;
}
.columnSideAdd {
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  min-height: 350px;
  position: relative;
}

.buttonInTable {
  font-size: 12px;
  border-radius: 5px;
  border: solid #efefef 1px;
  padding: 0.6rem 1rem;
  vertical-align: middle;
  margin-left: 1rem;
  position: relative;
  bottom: 0.15rem;
  background-color: white;
  cursor: pointer;
}
.buttonInTable:hover {
  background-color: #efefef;
}

.columnSide:nth-last-child(1) {
  border-left: solid #e5e5e5 1px;
}

.columnHeader {
  width: 100%;
  height: 55px;
  padding: 0 1rem;
  background-image: linear-gradient(to right, rgba(222, 222, 222, 1) 40%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.columnContent {
  position: absolute;
  height: calc(100% - 54px);
  bottom: 0;
  overflow-y: auto;
}

.userListModalContent {
  position: absolute;
  height: calc(100% - 90px);
  bottom: 0;
  overflow-y: auto;
}

.emptyTopic {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #E0E0E0;
}

.coverBox {
  height: 130px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.coverBox.firstCover {
  border: solid #5F65FF 3px;
}

.coverBox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inputBox {
  display: inline-block;
  width: 100%;
}

.inputBox label {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0.3rem;
  width: 100%;
  display: inline-block;
}

.inputBox input, .inputBox textarea {
  border: solid #efefef 1px;
  border-radius: 7px;
  padding: 0.7rem 1rem;
  font-size: 16px;
  font-family: Roboto;
  width: 100%;
}

textarea {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.inputBox.active label {
  color: #5F65FF;
}


.inputBox.active input {
  border: solid #5F65FF 1px;
}

input:focus, textarea:focus {
  outline: none;
  border: solid #5F65FF 1px;
}

.input-width_170 {
  max-width: 170px;
}

.input-width_300 {
  max-width: 300px;

}

.normalButton {
  padding: 8px 15px;
  border-radius: 7px;
}

.horizontalLine {
  width: 60px;
  height: 1px;
  background-color: #d3d3d3;
  margin: 1.5rem 0 2rem;
}

.emptyTopic p {
  max-width: 400px;
}

.endBorderTable.tableStructure tr td:nth-last-child(1),
.endBorderTable.tableStructure tr td:nth-last-child(2) {
  background: none;
}

.endBorderTable.tableStructure thead th:nth-last-child(1),
.endBorderTable.tableStructure thead th:nth-last-child(2) {
  background: none;
}

.popUpBox {
  border-radius: 25px;
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 600px;
  z-index: 3;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}

.popupClose {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.popupHeader {
  padding: 1rem;
  border-bottom: solid #efefef 1px;
}

.popUpContent {
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.noHeader .columnContent {
  height: 100%;
}

.tableStructure tr.active {
  background: #dbdada none;
}

/* .tableStructure tr.active td {
  background: none;
  color: #fff;
} */
/* 
.tableStructure tr.active td .deleteRow {
  color: #fff;
} */

.tableStructure thead.haveActive tr {
  background: none;
}

.tableStructure tr.haveActive {
  background: none;
}

/* -------------------------------------- Dashboard Styles --------------------------------------*/

.dashboardItemBoxes {
  width: 49%;
  float: left;
}

.dashboardItem {
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  padding: 1.8rem;
  width: 31.77%;
  float: left;
  margin-right: 2.3333%;
  background-color: #fff;
  margin-bottom: 2.3333%;
}

.dashboardItem:nth-child(3n) {
  margin-right: 0;
}

.dashboardItem:nth-last-child(1),
.dashboardItem:nth-last-child(2),
.dashboardItem:nth-last-child(3) {
  margin-bottom: 0;
}

.itemLine {
  width: 100%;
  height: 5px;
  border-radius: 250px;
  margin: 1rem 0;
  display: inline-block;
}

.dashboardItem.topics .itemLine {
  background-color: #F4F4FF;
}

.dashboardItem.subTopic .itemLine {
  background-color: #FFF5ED;
}

.dashboardItem.users .itemLine {
  background-color: #EDFFF7;
}

.dashboardItem.surveys .itemLine {
  background-color: #FFF0F0;
}

.dashboardItem.questions .itemLine {
  background-color: #E3F9FF;
}

.dashboardItem.files .itemLine {
  background-color: #F0FCFF;
}

.dashboardItem.partners .itemLine {
  background-color: #FFF0F6;
}

.dashboardItem.commissions .itemLine {
  background-color: #F5F5F2;
}

.dashboardItem.messages .itemLine {
  background-color: #F9F5FF;
}

.itemCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mostItems {
  border-radius: 25px;
  width: 49%;
  float: right;
  height: 604px;
  overflow-y: auto;
}

.mostSelectBox {
  padding: 1.75rem 2.25rem;
  border-bottom: #585EF0 solid 1px;
}

.mostQuestionItem {
  padding: 1.75rem 2.25rem;
  border-bottom: #585EF0 solid 1px;
}

.mostQuestionItem:nth-last-child(1) {
  border-bottom: none;
}

.mostQuestionCounter {
  margin-top: 1rem;
}

.mainColumn {
  font-size: 24px;
  font-weight: 500;
}

.trendingTopicsTable .trendColumn {
  height: 39px;
}

.trendingTopicsTable .trendColumn:nth-child(1) {
  padding-left: 0;
  padding-right: 1rem;
}

.trendingTopicsTable .trendRow .trendColumn:nth-child(1) {
  width: 45%;
}

.trendingTopicsTable .trendRow .trendColumn:nth-child(2) {
  width: 35%;
}

.trendingTopicsTable .trendRow .trendColumn:nth-child(3) {
  width: 20%;
}

.trendingTopicsTable .trendRow .trendColumn:nth-child(1) {
  justify-content: space-between;
}

.trendingTopicsTable .verticalLine:nth-child(1) {
  left: 46%;
}

.trendingTopicsTable .verticalLine:nth-child(2) {
  left: 76.5%;
}

.partnerLogo {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid #efefef 3px;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.verticalLine {
  width: 1px;
  background-image: linear-gradient(rgba(112, 112, 112, 1) 15%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 10px;
  background-repeat: repeat-y;
  height: calc(100% - 122px);
  display: inline-block;
  position: absolute;
  bottom: 40px;
}

.bestPartnersTable .trendColumn {
  height: 50px;
}

.bestPartnersTable .trendRow .trendColumn:nth-child(1) {
  width: 30%;
}

.bestPartnersTable .trendRow .trendColumn:nth-child(2) {
  width: 20%;
}

.bestPartnersTable .trendRow .trendColumn:nth-child(3) {
  width: 20%;
}

.bestPartnersTable .trendRow .trendColumn:nth-child(4) {
  width: 30%;
}

.bestPartnersTable .verticalLine:nth-child(1) {
  left: 33%;
}

.bestPartnersTable .verticalLine:nth-child(2) {
  left: 50.6%;
}

.bestPartnersTable .verticalLine:nth-child(3) {
  left: 67.9%;
}

/* -------------------------------------- Users Styles --------------------------------------*/

.sortSystem {
  border-radius: 10px;
  padding: 0.8rem 1.25rem;
  margin-right: 1rem;
  margin-left: 10px;
}

.mainTitle {
  margin-right: 1rem;
}

.viewMode {
  color: #d3d3d3;
}

.viewMode .icon-cart-view {
  margin-right: 0.5rem;
}

.changeDate {
  margin-right: 0.5rem;
}

.filters {
  margin-right: 1rem;
}

/* -------------------------------------- Topics Styles --------------------------------------*/

.bottomSideMargin {
  margin-bottom: 0.35rem;
}

.bottomUnitMargin {
  margin-bottom: 1rem;
}

.bottomNormalMargin {
  margin-bottom: 2rem;
}

.coverOrganizer {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: solid #efefef 1px;
}

.addTopicContainer {
  padding: 2rem;
}

.verticalSmallLine {
  width: 2px;
  height: 15px;
  background-color: #EBEBEB;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1.75rem;
}

.popUpContent p {
  margin-bottom: 2rem;
}


/* -------------------------------------- Map Styles --------------------------------------*/

.profilesList {
  white-space: nowrap;
  overflow-x: auto;
  border-bottom: solid #efefef 1px;
}

.aggregateList {
  white-space: nowrap;
  overflow-x: auto;
  border: solid #efefef 1px;
  border-radius: 5px;
  margin:5px;
}
.aggregateListnoBorder {
  white-space: nowrap;
  overflow-x: auto;
 
  margin:5px;
}

.addProfile {
  padding: 0.75rem 0.75rem 0.75rem 0;
  margin-right: 0.25rem;
}

.addAggregate {
  padding: 0.75rem;
}

.profilesList ul li, .aggregateList ul li {
  display: inline-block;
  padding: 0.75rem;
}

.mapInner {
  margin: 1rem 0;
}

.popUpContent .aggregateList {
  border: none;
}

.popUpContent .addAggregate {
  padding-left: 0;
}

.tagItem ul li {
  padding: 0.5rem 0.75rem;
  border: solid #efefef 1px;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.tagItem {
  margin: 1.5rem 0;
}

.aggregateName {
  width: calc(100% - 170px);
  float: left;
  margin-top: -1.4rem;
}


.aggregateForm {
  border-top: solid #efefef 1px;
  padding-top: 2rem;
}


.profiles.popup .popUpBox {
  max-width: 700px;
}

/* -------------------------------------- Plans Styles --------------------------------------*/

.createNewPlan {
  padding: 0.6rem 1.25rem;
  border: solid #5F65FF 2px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: #5F65FF;
}

.newButton {
  padding: 0.6rem 1.25rem;
  border: solid #f3f3f3 2px;
  font-size: 18px;
  font-weight: 500;
  color: black;
  width: 50%;
  text-align: center;
}
.activeRight{
  border-bottom: none;
  border-left: none;
}
.activeLeft{
  border-bottom: none;
  border-right: none;

}
.createNewFaq {
  padding: 0.6rem 1.25rem;
  border: solid rgb(17, 16, 20) 2px;
  font-weight: 500;
  border-radius: 5px;
  color: black;
}
.planCart {
  margin-right: 1%;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  width: 24.25%;
  margin-bottom: 1%;
}

.planCart:nth-child(4n) {
  margin-right: 0;
}

.userNumber {
  line-height: 1.5;
  margin-top: -0.15rem;
}

.usersAssigned, .planActions {
  padding: 1rem 1.75rem;
}

.planDescription {
  /* height: 50px; */
  margin: 0.75rem 0 1.25rem;
  overflow-y: hidden;
}

.planCarts {
  display: flex;
  flex-wrap: wrap;
}

.mainFeatures {
  background-color: #F8F8F8;
}

.mainFeatures li {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid #ededed 1px;
}

.addedFeatures li {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid #efefef 1px;
}

.acceptFeature, .editFeature, .rejectFeature {
  width: 30px;
  height: 30px;
  border: solid #ededed 1px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.acceptFeature {
  color: #18C37A;
}

.editFeature {
  color: #5F65FF;
}

.rejectFeature {
  background-color: #FF6767;
  border: none;
  color: #fff;
}

.defaultFilter {
  border: solid #efefef 1px;
  border-radius: 5px;
  padding: 0.3rem 1rem;
}

.featuresAction .deleteRow {
  padding: 0.4rem 1rem;
}

.features.columnContent {
  position: absolute;
  height: calc(100% - 154px);
  bottom: 100px;
  overflow-y: auto;
}

.featureForm {
  position: absolute;
  bottom: 0;
  right: 0;
}

.featureForm .aggregateForm {
  padding: 2rem 1.5rem 1.5rem;
}

/* -------------------------------------- Content Pages Styles --------------------------------------*/

.quarterColumn {
  width: 25%;
}

.threeQuarterColumn {
  width: 75%;
}

.websiteContent .tableStructure tr td:nth-last-child(1) {
  text-align: left;
}

.threeForthColumn {
  width: 65%;
}

.oneForthColumn {
  width: 35%;
}

/* -------------------------------------- Logos Styles --------------------------------------*/

.mainLogo, .footerLogo {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid #5F65FF 2px;
  position: relative;
}

.favIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid #5F65FF 2px;
  position: relative;
}

.mainLogo img, .footerLogo img, .favIcon img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

/* -------------------------------------- FAQ Styles --------------------------------------*/

.twentyColumn {
  width: 20%;
}

.fortyColumn {
  width: 40%;
}

.leftBorder {

  border-left: solid #e5e5e5 1px;
}

.addCategory {
  padding: 0.2rem 0.5rem;
  font-size: 14px;
  border: solid #5F65FF 2px;
  border-radius: 5px;
  color: #5F65FF;
}

.startSession, .endSession {
  width: 130px;
  display: inline-block;
}

.displayFlex{
  display: flex;
}

.innerUserId, .innerQuestionId {
  width: 10%;
  float: left;
}

.innerEmail {
  width: 25%;
  float: left;
}

.innerEmailWider {
  width: 30%;
  float: left;
}

.innerSessionSingle {
  width: 25%;
  float: left;
}

.innerIp {
  width: 15%;
  float: left;
}

.innerQuestionTitle {
  width: 35%;
  float: left;
  margin-right: 5%;
}

.innerAccessLevel, .innerSurveyId {
  width: 20%;
  float: left;
}

.innerSession, .innerSurveyTitle {
  width: 30%;
  float: left;
}

.userInnerBox, .visitInfo {
  background-color: #fff;
  padding: 3rem;
  border-radius: 25px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
}

.visitInfo {
  padding: 0;
}

.innerBottomShelf, .innerMiddleShelf {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: solid #efefef 1px;
}

.halfColumn {
  width: 50%;
  float: left;
  padding: 2rem;
}

.halfColumn:nth-last-child(1) {
  border-left: solid #efefef 1px;
}

.innerInclude,
.innerExclude {
  width: 20%;
}

.innerCoverage {
  width: 15%;
}

.innerSubTopics {
  width: 30%;
}

.innerDates {
  width: 15%;
}

.innerQuestionAccessedId {
  width: 15%;
}

.innerSurveyAccessedId {
  width: 10%;
  margin-right: 5%;
}

.innerQuestionAccessedTitle {
  width: 35%;
}

.innerSurveyAccessedTitle {
  width: 30%;
}

.oneQuarterColumn {
  width: 27%;
  float: left;
}

.threeQuarterColumn {
  width: 73%;
  float: right;
}

.messagedUsers li {
  padding: 1.25rem;
  border-bottom: solid #efefef 1px;
}

.messagedUsers li.unread .messageLine {
  background-color: #FFF0F6;
}

.messagedUsers li.unread .icon.icon-time {
  color: #FD1778;
}

.messagedUsers li.unread .messengerName,
.messagedUsers li.unread .messageBrief {
  font-weight: 500;
}

.messagedUsers li.selected .messengerName,
.messagedUsers li.selected .messageBrief {
  font-weight: 500;
}

.messagedUsers li.selected {
  color: #fff;
  background-color: #5F65FF;
}

.messagedUsers li.selected .messageLine {
  background-color: #545AFC;
}

.messageBrief {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageLine {
  width: 7px;
  height: 65px;
  background-color: #EBECFF;
  border-radius: 7px;
  display: inline-block;
  vertical-align: middle;
}

.notifMesssageContent {
  width: calc(100% - 40px);
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
}

.messageHeader {
  padding: 2rem;
  border-bottom: solid #efefef 1px;
}

.messageSendButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.messageComposeField {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: solid #efefef 1px;
}

.writeMessage {
  padding: 2rem;
  border: none;
  font-size: 16px;
  height: 86px;
  display: block;
  width: calc(100% - 80px);
  min-width: calc(100% - 80px);
}

.writeMessage:focus {
  border: none;
}

.messageContainer {
  background-color: #EAEAEA;
  border-radius: 7px 7px 7px 0;
  padding: 0.5rem 1rem;
  position: relative;
  max-width: 600px;
  width: auto;
}

.adminMessage .messageContainer {
  border-radius: 7px 7px 0 7px;
}

.messageContent {
  width: auto;
}

.messageTime {
  color: #A5A5A5;
  float: right;
  position: relative;
  right: -0.3rem;
  margin-top: 0.2rem;
}

.userMessage, .adminMessage {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: calc(100% - 90px);
  margin-bottom: 1rem;
}

.userMessage {
  left: 45px;
}

.adminMessage {
  right: 45px;
}

.userPointer, .adminPointer {
  position: absolute;
  bottom: 0;
}

.userPointer {
  left: -10px;
}

.adminPointer {
  right: -10px;
}

.userImage, .adminImage {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 250px;
  font-size: 10px;
  font-weight: 300;
  color: #fff;
  position: absolute;
  bottom: 0;
}

.adminImage {
  right: -40px;
}

.userImage {
  left: -40px;
}

.messageActions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
}

.adminMessage .messageActions {
  left: -25px;
  right: unset;
}

.conversationDate {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}

.dashedLine {
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to right, rgba(222, 222, 222, 1) 30%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.dateInner {
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  position: relative;
  bottom: 2px;
}

.messageMainContent {
  padding: 2rem;
  position: absolute;
  top: 5.8rem;
  height: calc(100% - 180px);
  overflow-y: auto;
}

.maxHeight {
  max-height: 800px;
  overflow-y: auto;
}

.clickShape {
  cursor: pointer;
}

/*css styles for login page
 */

.loginPopup{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #dcdbdb;
}

.loginPopup .signInBox {
  max-width: 500px;
  background-color: #F0F0F0;
  color: #333;
}

.loginPopup button.enter {
  background-color: #4DB6AC;
  color: #fff;
}

.loginPopup button.gmailEnter {
  border: solid #333 1px;
  color: #333;
}

.loginPopup .signInBox {
  padding: 1rem 2rem 0;
  z-index: 2;
}

.loginPopup .normalSignIn {
  border-color: #DEDEDE;
  padding-top: 0;
  padding-bottom: 0;
}

.loginPopup .gmailSignIn {
  padding-top: 0;
  padding-bottom: 0;
}

.loginPopup .registerHere {
  padding: 1.25rem;
  border-top: solid #e1e1e1 1px;
  margin-top: 2rem;
}

.loginPopup .backSignIn {
  background-color: rgba(255, 255, 255, 0.38);
}
.loginPopup {
  z-index: 1;
}

.loginPopup .remindMe .checkContainer input:checked ~ .checkmark {
  background-color: #333;
  font-size: 14px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.loginPopup .checkmark {
  border: solid #333 1px;
  color: #F0F0F0;
  font-size: 14px;
}
.signInBox {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  float: right;
  width: 100%;
  position: relative;
  margin-top: 4rem;
}
.SignInBoxInner {
  position: relative;
  z-index: 1;
  background-color: #F0F0F0;
}
.originalBackground {
  background-color: #1E3B60;
}
.backSignIn {
  background-color: rgba(101,136,144,0.34);
  transform: rotate(3deg);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.normalSignIn {
  padding: 2.5rem;
  width: 100%;
  float: right;
}
.signInForm {
  margin-top: 1.5rem;
}

.signInForm input {
  text-align: center;
  margin-bottom: 1.5rem;
}
.remindMe {
  float: right;
}
.checkContainer {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkContainer:hover input ~ .checkmark {
  border: solid 1px #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #4DB6AC;
  color: #fff;
  border: none;
}
label.checkContainer {
  font-weight: normal;
  position: relative;
  background-color: transparent;
  padding: 0;
  z-index: 1;
  color: transparent;

  top: 0;
  right: 0;
  margin-left: 0.5rem;
}
.checkContainer {
  width: 22px;
  height: 22px;
  border-radius: 4px;

  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  vertical-align: middle;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkContainer input:checked ~ .checkmark {
  background-color: #fff;
  color: #333;
}
.checkContainer input:checked ~ .checkmark:after {
  display: block;
  color: #333;
}
.forget {
  float: left;
  text-decoration: underline;
}
button.enter {
  width: 160px;
  text-align: center;
  background-color: #fff;
  color: #333;
  padding: 1rem;
  font-size: 18px;
  font-weight: 500;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 2.5rem;
  font-family: iranyekan;
}
.registerHere {
  margin-top: 3rem;
  font-size: 16px;
}

.noMessageSelected {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  white-space: nowrap;
}

.checkbox {
  display: inline-block;
  width: unset;
  padding: 2px 2px;
  border-radius: 5px;
  border: 2px solid #5f65ff;
  color: black;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

.checkbox input {
  color : #5f65ff ;
  margin-right : 4px ;
  padding-top : 0.4rem  ;
  padding-bottom: 0.4rem ;
}

.isSelected {
  background-color: #cbcdff;
}


.accessLevelInTable {
  border-radius: 5px;
  text-align: center;
  padding: 8px 1px;
  font-weight: 450;
}

.chatSearch {
  border-radius: 25px 0 0 0 !important;
}
.selectUserPopUp {
  max-width: 500px !important;
}
.selectUserPadding {
  padding: 1rem !important;
}

.selectUserLi {
  border: solid #efefef 1px !important;
  border-radius: 20px !important;
  margin-top: 5px;
  width: 100%;
}
.userLiOnHover {
  background-color: #ffd6d6 ;
}

.inlineFlex {
  display: inline-flex;
}

.userIcon {
  width: 53px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 250px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  bottom: 0;
  background-color: rgb(255, 103, 103);
  margin-left: 5px;
  margin-top: 8px;
}

.filepond--root {
  height: 130px !important;
}

.colorPicker {
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  border-radius: 30px;
}
.accColorSelectBox {
  border: 1px solid blue;
  border-radius: 6px;
  padding: 7px;
  /* margin-left: 0.5rem; */
  align-items: center;
}
.accColorTitle {
  margin-top: 10px;
  margin-right: 10px;
}
.colorString {
  font-weight: 900;
  margin-left: 5px;
}

.cke_contents.cke_reset {
  width: 100% !important;
}
.cke_toolbar_break {
  display: none !important;
}

.inputBox.active .css-jibygn-container {
  border-color: #5F65FF;
}

.inputBox.active textarea {
  border-color: #5F65FF;
}
.inputBox.active .cke.cke_reset.cke_chrome.cke_browser_webkit {
  border-color: #5F65FF !important;
}

.chartBox {
  width: 100% !important;
  margin-top: 3rem !important;

}

.chartItem.active {
  border: 1px solid #2d7222;
  border-radius: 20px;
  background-color: #2d7222;
  color: white ;
}

.chartItem {
  padding: 5px 10px;
}

/*.chartItem.active .itemCircle {*/
/*  background-color: white !important;*/
/*  border: 1px solid white !important;*/
/*}*/
.chartItem.active .chartItemText {
  color: white !important;
}

.titleItems {
  /* width: 30%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleItemsWithoutWidth{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTopicTopBar {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}
.backToTopics {
  color: #c4c1c1;
  padding: 0 6px;
  /*border-right: 1px solid #c4c1c1;*/
}

.topicRow {
cursor: pointer;
}
.topicRow.selected {
  background-color: #f5f5f5 !important;
  font-weight: bold;
}



.createNew {
  padding: 0.6rem 1.25rem;
  border: solid #000000 2px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: #ffffff;
}




/* -------------------------------------- File upload Styles --------------------------------------*/

@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);


.uploader {
  clear: both;
  margin: 0 auto;
  width: 100%;
}
.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border:solid #efefef 2px;
  border-top: none;
  transition: all 0.2s ease;
  user-select: none;
}

.uploader label.hover #start i.fa {
  transform: scale(0.8);
  opacity: 0.3;
}
.uploader #start {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #start.hidden {
  display: none;
}
.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}
.uploader #response {
  float: left;
  clear: both;
  width: 100%;
}
.uploader #response.hidden {
  display: none;
}
.uploader #response #messages {
  margin-bottom: 0.5rem;
}
.uploader #file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  max-width: 180px;
}
.uploader #file-image.hidden {
  display: none;
}
.uploader #notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}
.uploader #notimage.hidden {
  display: none;
}
.uploader progress, .uploader .progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  max-width: 180px;
  border: 0;
  background-color: #eee;
  overflow: hidden;
}
.uploader .progress[value]::-webkit-progress-bar {
  border-radius: 4px;
  background-color: #eee;
}
.uploader .progress[value]::-webkit-progress-value {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}
.uploader .progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, #393f90 0%, #454cad 50%);
  border-radius: 4px;
}

.uploader div {
  margin: 0 0 0.5rem 0;
  color: #5f6982;
}


/**/
.custom-file-input {
  color: transparent;
  width: 95px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select a file';
  color: white;
  font-size: 14px;
  display: inline-block;
  background: #18C37A;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  border-top: none;
}
.custom-file-input:hover::before {
  border-color: white;
}
.custom-file-input:active::before {
  display: none;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}


.profileItem {
  border-radius: 10px;
  box-shadow: 0 3px 4px rgba(105, 105, 105, 0.18);
  padding: 1rem;
  text-align: center;
  float: left;
  margin-right: 3%;
  background-color: rgb(231, 231, 231);
  margin-bottom: 3%;
}
.profileItem.active {
  
  background-color: #78bef8;
}

.profileItem.disable {
  background-color: #949494;
  color:#666666;
  box-shadow: none;
  
  cursor: not-allowed;
  pointer-events: none;
}
.clickShape.disable{
  cursor: not-allowed;
  pointer-events: none;
}

.uploadDiv{
    text-align: -webkit-center;
    border: solid #efefef 1px;
    border-radius: 7px;
    padding: 10px;
    padding-top: 15px;
}
.geoactive{
  background-color: #5F65FF;
  color: #fff;
}

.uploadLabel{
  font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.3rem;
    width: 100%;
    display: inline-block;
}
/*table*/

.width15rem{
  width:15rem
}


.popUpBox {
  border-radius: 10px !important;
}

.popUpContent {

  margin-top: 0px !important;

}


.topUnitMargin {
  margin-top: 0px;
}


.inputBox {
  margin-top: 15px;
}


.MuiButtonBase-root {
  margin-right: 14px  !important;
}


.css-6hp17o-MuiList-root-MuiMenu-list {
  margin-left: 10px !important;
}



.garage-title {
  clear: both;
  display: inline-block;
  white-space: nowrap;
}



.btnContainer{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* gap: 15px; */
  margin: 20px;
}

.custom-button {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  border: 1px solid transparent;
  background-color: #e53e3e; 
  padding: 0.5rem 1rem;
  font-size: 1rem; 
  font-weight: 500;
  color: #ffffff; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  text-decoration: none; 
  border-radius: 0.375rem; 
}

.custom-button:hover {
  background-color: #c53030; 
}

.custom-button:focus {
  outline: none;
  border: 2px solid #e53e3e;
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5); 
}

.custom-button:focus:not(:focus-visible) {
  box-shadow: none;
}

.custom-button:focus-visible {
  border: 2px solid #e53e3e; 
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5); 
}


@media (min-width: 640px) {
  .custom-button {
    margin-left: 0.75rem;
    width: auto; 
    font-size: 0.875rem; 
  }
}







.Cancel-button {
  margin-top: 0.75rem; 
  display: inline-flex;
  width: 100%;
  justify-content: center;
  border: 1px solid #d1d5db;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #4b5563; 
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
  cursor: pointer;
  text-decoration: none; 
  border-radius: 0.375rem; 
}

.Cancel-button:hover {
  background-color: #f9fafb; 
}

.Cancel-button:focus {
  outline: none;
  border: 2px solid #4f46e5; 
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
}

.Cancel-button:focus:not(:focus-visible) {
  box-shadow: none;
}

.Cancel-button:focus-visible {
  border: 2px solid #4f46e5; 
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.5);
}


@media (min-width: 640px) {
  .Cancel-button {
    margin-top: 0; 
    margin-left: 0.75rem;
    width: auto; 
    font-size: 0.875rem;
  }
}


.css-1ycxvqq-MuiSwitch-root {
  width: 46px !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -16px !important;
}


