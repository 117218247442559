.row--NotApproved{
    background-color:#fa8c8c
}

.row--NotApproved:hover{
    background-color:#f37777 !important
}

.row--NotSet{
    background-color:#fa8c8c
}

.row--NotSet:hover{
    background-color:#f37777 !important
}

.keyItem{
    padding: 5px;
    border-radius: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    white-space: nowrap;
    line-height: 56px;
}

.cursorPointer{
    cursor: pointer;
}